// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-theme-firebase-src-pages-login-tsx": () => import("./../../../node_modules/gatsby-theme-firebase/src/pages/login.tsx" /* webpackChunkName: "component---node-modules-gatsby-theme-firebase-src-pages-login-tsx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-notes-tsx": () => import("./../../../src/pages/notes.tsx" /* webpackChunkName: "component---src-pages-notes-tsx" */),
  "component---src-pages-privacypolicy-tsx": () => import("./../../../src/pages/privacypolicy.tsx" /* webpackChunkName: "component---src-pages-privacypolicy-tsx" */),
  "component---src-pages-pvlink-tsx": () => import("./../../../src/pages/pvlink.tsx" /* webpackChunkName: "component---src-pages-pvlink-tsx" */),
  "component---src-pages-termscondition-tsx": () => import("./../../../src/pages/termscondition.tsx" /* webpackChunkName: "component---src-pages-termscondition-tsx" */)
}

